<template>
  <b-modal :id="id" @ok="callOk" @hide="hide" @shown="shown()" :title="title">
    <template slot="default">
      <div v-if="useCamera">
        <StreamBarcodeReader
          @decode="onDecode"
          @loaded="onLoaded"
        ></StreamBarcodeReader>
      </div>

      <div class="row" v-else>
        <div class="col-12">
          <div v-if="loading" class="mb-2">
            <loading-animation />
          </div>
          <b-form-input v-else v-model="barcode" @keyup.enter="callOk" @keydown.tab="callOk" ref="barcodeInput" />
          <p v-if="lastBarcode && lastBarcode.length" class="mt-3 text-center">
            Último código de barras: {{lastBarcode}}
          </p>
        </div>
        <div v-if="withQuantity" label="Quantidade" class="col-12 mb-0 mt-3">
          <label for="quantity" class="mb-0 margin-right pb-2">Quantidade a ser {{ isEntrance ? 'inserida' : 'removida'}}:</label>
          <div class="row align-items-center">
            <div v-if="itemLot && itemLot.quantityPerVolume" class="col">
              <b-form-input type="text" v-model="numberOfValues"/>
            </div>
            <div v-if="itemLot && itemLot.quantityPerVolume" class="col-auto px-0">
              <span class="text-muted">x </span> {{ itemLot.quantityPerVolume }} =
            </div>
            <div class="col">
              <b-form-input type="text" v-model="quantity"/>
            </div>
          </div>
        </div>
      </div>

    </template>

    <template slot="modal-footer" slot-scope="{ ok, cancel }">
      <b-button variant="secondary" @click="cancel()">Cancelar</b-button>
      <b-button :disabled="loading" variant="primary" @click="callOk">Ok</b-button>
    </template>
  </b-modal>
</template>

<script>
  import { LoadingAnimation } from '@/components/loaders';
  import { StreamBarcodeReader } from "vue-barcode-reader";
  import shared from '@/shared/shared';

  export default {
    name: 'barcode-input-modal',
    components: { LoadingAnimation, StreamBarcodeReader },

    props: {
      id: {
        type: String,
        require
      },
      title: {
        type: String,
        default: 'Insira o código de barras'
      },
      attempts: {
        type: Number
      },
      loading: {
        type: Boolean
      },
      withQuantity: {
        type: Boolean,
        default: false
      },
      isEntrance: {
        type: Boolean
      },
      initialQuantity: {},
      ok: {
        type: Function,
        default () {
          return;
        },
      },
      hide: {
        type: Function,
        default () {
          return;
        },
      },
      itemLot: {
        type: Object
      },
      config: {
        type: Object
      },
      authUser: {
        type: Object
      },
      parameter: {
        type: Object
      }
    },

    data() {
      return {
        lastBarcode: null,
        barcode: null,
        quantity: null,
        numberOfValues: null,
        innerConfig: {}
      }
    },

    computed: {
      useCamera() {
        return shared.useCamera(this.parameter, this.innerConfig);
      }
    },

    methods: {
      focusBarcodeInput() {
        this.barcode = null;

        if (this.$refs.barcodeInput) {
          let _this = this;
          setTimeout(() => {
            if (_this.$refs.barcodeInput) {
              _this.$refs.barcodeInput.$el.focus();
            }
          }, 500);
        }
      },

      callOk() {
        this.ok(this.barcode, this.quantity);
      },

      shown() {
        if (this.config) {
          this.innerConfig = this.config;
        } else {
          this.innerConfig = shared.getConfig();
        }

        if (!this.useCamera) {
          this.focusBarcodeInput();
        }
      },

      onDecode(text) {
        //console.log(`Decode text from QR code is ${text}`)
        this.barcode = text;
        this.callOk();
      },

      onLoaded() {
        console.log(`Leitor de códigos de barra carregado`)
      },
    },

    watch: {
      attempts(newValue, oldValue) {
        if (this.barcode && this.barcode.length) {
          this.lastBarcode = this.barcode;
        }

        this.focusBarcodeInput();
      },

      initialQuantity(newValue, oldValue) {
        if (this.withQuantity) {
          this.quantity = newValue;
          this.numberOfValues = 1;
        }
      },

      numberOfValues(newValue, oldValue) {
        if(this.quantity && this.itemLot && this.itemLot.quantityPerVolume && newValue){
          this.$set(this, 'quantity', newValue * this.itemLot.quantityPerVolume)
        }
      }
    }
  }
</script>

<style scoped>
</style>
